import { Flex, Image, chakra, shouldForwardProp } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export function LoadingScreen() {
  return (
    <Flex
      h="100vh"
      w="100vw"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <ChakraBox
        h="200px"
        animate={{
          scale: [1, 1.1, 1, 1.1, 1],
          rotate: [-10, 10, -10],
        }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: 2,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
      >
        <Image src="logo.svg" alt="Roel.dad logo" h="full" fill="red" />
      </ChakraBox>
    </Flex>
  );
}
