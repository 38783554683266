import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  User,
} from "firebase/auth";
import { auth, provider } from "../firebase";
import { LoadingScreen } from "../components/Common/LoadingScreen";

const UserAuthContext = createContext<any>({});

type Props = {
  children: JSX.Element;
};

export function UserAuthContextProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null);
  const [pending, setPending] = useState(true);

  function signIn() {
    return signInWithPopup(auth, provider);
  }

  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      setPending(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (pending) {
    return <LoadingScreen />;
  }

  return (
    <UserAuthContext.Provider value={{ user, signIn, logOut }}>
      {children}
    </UserAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(UserAuthContext);
}
