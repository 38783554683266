import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  Image,
  Icon,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  AspectRatio,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { IPost } from "../../interfaces/IPost";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { formatDistance } from "date-fns";
import { nl } from "date-fns/locale";
import {
  arrayRemove,
  arrayUnion,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";
import { useIntlTimestamp } from "../../hooks/useIntlTimestamp";

type Props = {
  post: IPost;
};

export function Post({ post }: Props) {
  const { user } = useUserAuth();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { intlPostDate, intlPostTime } = useIntlTimestamp(post.date);
  const [isExactDate, setIsExactDate] = useState(false);
  const toast = useToast();
  const isLikedByYou = post.likes.includes(user.uid);

  const docRef = doc(db, "roel.dad", post.id);
  const dataToUpdate = {
    likes: arrayUnion(user.uid),
  };

  const dataToRemove = {
    likes: arrayRemove(user.uid),
  };

  const handleLike = () => {
    if (isLikedByYou) {
      updateDoc(docRef, dataToRemove)
        .then(() => console.log("unlike"))
        .catch((e) => console.error(e));
    } else {
      updateDoc(docRef, dataToUpdate)
        .then(() => console.log("like"))
        .catch((e) => console.error(e));
    }
  };

  const handleDelete = () => {
    const postDoc = doc(db, "roel.dad", post.id);
    deleteDoc(postDoc)
      .then()
      .catch((err) => {
        console.error(err);
        toast({
          status: "error",
          description: "Kon het bericht niet verwijderen",
          isClosable: true,
        });
      });
  };

  const distance = formatDistance(
    new Date(),
    new Date(post.date.seconds * 1000),
    {
      locale: nl,
    }
  );

  return (
    <Card
      w="full"
      borderRadius={0}
      boxShadow="none"
      color="brand.black"
      borderTop="1px solid"
      borderColor="brand.gray"
      pt={1}
      pb={3}
    >
      <CardHeader pb={0} px={3}>
        <Flex gap="4">
          <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
            <Avatar
              name={post.author.name}
              src={post.author.photoUrl}
              color="brand.white"
            />

            <Box onClick={() => setIsExactDate(!isExactDate)}>
              <Heading as="h4" size="sm" color="brand.textPrimary">
                {post.author.name}
              </Heading>
              <Text color="brand.textSecondary" fontSize="sm">
                {isExactDate
                  ? `${intlPostDate} - ${intlPostTime}`
                  : `${distance} geleden`}
              </Text>
            </Box>
          </Flex>
          <HStack>
            {/* TODO: Display all users that liked the post in a tooltip  */}
            <Text
              fontWeight="medium"
              color={isLikedByYou ? "brand.accent" : "brand.textPrimary"}
              mb={0.5}
            >
              {post.likes.length || 0}
            </Text>
            <Icon
              as={isLikedByYou ? FaHeart : FaRegHeart}
              color={isLikedByYou ? "brand.accent" : "brand.textPrimary"}
              onClick={handleLike}
              _hover={{ cursor: "pointer" }}
              fontSize="xl"
            />
            <Menu>
              <MenuButton
                as={IconButton}
                variant="ghost"
                colorScheme="gray"
                aria-label="See menu"
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                icon={<BsThreeDotsVertical />}
                isDisabled={post.author.id !== user.uid}
              />
              <MenuList>
                <MenuItem onClick={handleDelete}>Bericht verwijderen</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </CardHeader>
      <CardBody px={3}>
        <Flex flexDir="column" gap={5}>
          {post.message && (
            <Text px={2} whiteSpace="pre-line">
              {post.message}
            </Text>
          )}
          {/* TODO: Display multiple pictures and allow them to be fullscreen  */}
          {post.media && post.media.type === "image" && post.media.url && (
            <AspectRatio w="full" ratio={4 / 3} onClick={onOpen}>
              <Image
                objectFit="cover"
                objectPosition="center center"
                src={post.media.url}
                alt="Post image"
                borderRadius={8}
              />
            </AspectRatio>
          )}
          {post.media && post.media.type === "video" && post.media.url && (
            <AspectRatio w="full" ratio={16 / 9} borderRadius={8}>
              <iframe
                title={post.media.url}
                src={post.media.url}
                style={{ borderRadius: "0.5rem" }}
                allowFullScreen
              />
            </AspectRatio>
          )}
        </Flex>
      </CardBody>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent bgColor="black" maxH="100vh" onClick={onClose}>
          <ModalBody
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              maxW="full"
              maxH="90vh"
              src={post?.media?.url}
              alt="Post image"
              borderRadius={5}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
}
