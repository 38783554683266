import { intlFormat } from "date-fns";
import { IDate } from "../interfaces/IDate";

export function useIntlTimestamp(date: IDate) {
  const intlPostTime = intlFormat(
    new Date(date.seconds * 1000),
    {
      hour: "2-digit",
      minute: "2-digit",
    },
    {
      locale: "nl-NL",
    }
  );
  const intlPostDate = intlFormat(
    new Date(date.seconds * 1000),
    {
      year: "numeric",
      month: "short",
      day: "2-digit",
    },
    {
      locale: "nl-NL",
    }
  );

  return { intlPostDate, intlPostTime };
}
