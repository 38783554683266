import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "brand.bgPrimary",
        color: "brand.textPrimary",
      },
    },
  },
  colors: {
    brand: {
      black: "#323e48",
      gray: "#dddfe2",
      white: "#fcfcfc",
      accent: "#3A6B94",
      bgPrimary: "#ffffff",
      bgSecondary: "#f4f7fe",
      textPrimary: "#020101",
      textSecondary: "#909090",
    },
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: "brand.black",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "brand.black",
      },
    },
    Button: {
      variants: {
        outline: {
          borderRadius: "full",
          borderColor: "brand.black",
          px: 8,
          py: 6,
          _hover: {
            bgColor: "transparent",
          },
          _active: { bgColor: "transparent" },
        },
      },
    },
  },
});
