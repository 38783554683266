import { Layout } from "../components/Common/Layout";
import { Suspense, useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { IPost } from "../interfaces/IPost";
import { LoadingSkeleton } from "../components/Common/LoadingSkeleton";
import { LoadingScreen } from "../components/Common/LoadingScreen";
import { CreatePost } from "../components/Post/CreatePost";
import { Post } from "../components/Post/Post";

export function HomePage() {
  const [posts, setPosts] = useState<IPost[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const collectionRef = collection(db, "roel.dad");
  const q = query(collectionRef, orderBy("date", "desc"));

  useEffect(() => {
    const unsubscribe = onSnapshot(q, (snapshot) => {
      // TODO: Set loading state to display the skeleton below
      setPosts(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          author: doc.data().author,
          date: doc.data().date,
          type: doc.data().type,
          message: doc.data().message,
          media: doc.data().media,
          likes: doc.data().likes,
        }))
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Layout>
        <Flex flexDir="column">
          <CreatePost />
          {posts.map((post) => {
            return (
              <LoadingSkeleton key={post.id} isLoaded={!isLoading}>
                <Post post={post} />
              </LoadingSkeleton>
            );
          })}
          {!posts.length && (
            <Text
              w="full"
              textAlign="center"
              textTransform="uppercase"
              fontWeight="medium"
              letterSpacing={1}
              fontSize="sm"
              mt={3}
            >
              Helaas, er is nog niks gebeurd in het leven van Guusje
            </Text>
          )}
        </Flex>
      </Layout>
    </Suspense>
  );
}
