import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { ProtectedRoute } from "./components/Utils/ProtectedRoute";
import { UserAuthContextProvider } from "./contexts/UserAuthContext";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <UserAuthContextProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </Router>
      </UserAuthContextProvider>
    </ChakraProvider>
  );
};
