import { Navigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { useUserAuth } from "../../contexts/UserAuthContext";

type Props = {
  children: JSX.Element;
};

export function ProtectedRoute({ children }: Props) {
  const { user } = useUserAuth();

  if (!user) {
    return <Navigate replace to="/login" />;
  }

  return <Flex>{children}</Flex>;
}
