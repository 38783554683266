import { Skeleton } from "@chakra-ui/react";

type Props = {
  children: JSX.Element | JSX.Element[];
  isLoaded: boolean;
};

export function LoadingSkeleton({ children, isLoaded }: Props) {
  return (
    <Skeleton h="content" borderRadius={8} isLoaded={isLoaded}>
      {children}
    </Skeleton>
  );
}
