import { useNavigate } from "react-router-dom";
import { Box, Button, Image, Flex, Text, useToast } from "@chakra-ui/react";
import { useUserAuth } from "../contexts/UserAuthContext";

export function LoginPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const { signIn } = useUserAuth();

  const handleLogin = async () => {
    try {
      await signIn();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast({
        status: "error",
        description: "Inloggen mislukt",
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      h="100vh"
      w="100vw"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      gap={5}
    >
      <Box h="200px">
        <Image src="logo.svg" alt="Roel.dad logo" h="full" fill="red" />
      </Box>
      <Text textTransform="uppercase" fontWeight="bold" letterSpacing={1}>
        She's crazy like a fool. Wild about daddy Roel.
      </Text>
      <Button
        onClick={handleLogin}
        variant="outline"
        mt={12}
        color="brand.black"
        borderColor="brand.black"
      >
        Login met Google
      </Button>
    </Flex>
  );
}
