import {
  Card,
  CardHeader,
  Flex,
  Avatar,
  Input,
  Icon,
  FormControl,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  DrawerCloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Heading,
  Textarea,
  HStack,
} from "@chakra-ui/react";
import { MdInsertPhoto, MdSettings, MdVideocam } from "react-icons/md";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { useRef, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { db, auth, storage } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { FaPhotoVideo } from "react-icons/fa";

const schema = yup
  .object({
    message: yup.string().optional(),
    media: yup.object().optional(),
  })
  .required();

export function CreatePost() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useUserAuth();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });

  const collectionRef = collection(db, "roel.dad");

  const onSubmit = async (data: any) => {
    if (uploadFile) {
      const imgRef = ref(storage, `roel.dad/images/${uploadFile.name}`);
      await uploadBytes(imgRef, uploadFile).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await addDoc(collectionRef, {
            ...data,
            author: {
              name: auth.currentUser?.displayName,
              id: auth.currentUser?.uid,
              photoUrl: auth.currentUser?.photoURL,
            },
            likes: [],
            date: new Date(),
            media: { url: url, type: uploadFile.type.split("/")[0] },
          })
            .then()
            .catch((err) => {
              console.error(err);
              toast({
                status: "error",
                description: "Kon bericht niet plaatsen",
                isClosable: true,
              });
            })
            .finally(() => {
              reset();
              setUploadFile(null);
              onClose();
            });
        });
      });
    } else {
      await addDoc(collectionRef, {
        ...data,
        author: {
          name: auth.currentUser?.displayName,
          id: auth.currentUser?.uid,
          photoUrl: auth.currentUser?.photoURL,
        },
        likes: [],
        date: new Date(),
      })
        .then()
        .catch((err) => {
          console.error(err);
          toast({
            status: "error",
            description: "Kon bericht niet plaatsen",
            isClosable: true,
          });
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  // TODO: Allow multiple images

  return (
    <>
      <HStack px={3}>
        <Avatar
          name={user.displayName}
          src={user.photoURL}
          color="brand.white"
          h="40px"
          w="40px"
        />
        <Input
          onClick={onOpen}
          _hover={{ cursor: "pointer" }}
          placeholder="Vertel iets over Guusje"
          borderRadius="full"
          my={5}
          pl={5}
        />
      </HStack>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent m={3}>
          <ModalHeader
            borderBottom="1px solid"
            borderColor="brand.gray"
            textAlign="center"
          >
            Maak deel uit van Guusjes leven
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap" py={4}>
              <Avatar
                name={user.displayName}
                src={user.photoURL}
                color="brand.white"
              />

              <Box>
                <Heading as="h4" size="sm" color="brand.textPrimary">
                  {user.displayName}
                </Heading>
              </Box>
            </Flex>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Textarea
                {...register("message")}
                placeholder="Wat wil je toevoegen aan het leven van Guusje?"
                whiteSpace="pre-line"
              />
              <HStack pt={2} justifyContent="center">
                <Input
                  {...register("media")}
                  type="file"
                  ref={fileInputRef}
                  onChange={(event: any) => {
                    setUploadFile(event.target.files[0]);
                  }}
                  display="none"
                />
                <Button
                  display="inline-flex"
                  variant="unstyled"
                  leftIcon={<Icon as={FaPhotoVideo} fontSize="lg" />}
                  color={uploadFile ? "brand.accent" : "brand.black"}
                  mr={5}
                  onClick={() => {
                    fileInputRef.current && fileInputRef.current.click();
                  }}
                >
                  Foto / video toevoegen
                </Button>
              </HStack>
              <Button
                type="submit"
                isLoading={isSubmitting}
                w="full"
                mt={5}
                mb={2}
                color="brand.white"
                bgColor="brand.black"
                _hover={{ bg: "brand.black", opacity: 0.8 }}
                variant="outline"
                borderRadius={5}
                p={0}
              >
                Guusjes leven updaten
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
