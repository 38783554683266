import { useNavigate } from "react-router-dom";
import {
  Flex,
  Button,
  Image,
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  HStack,
  Text,
  Container,
  Icon,
} from "@chakra-ui/react";
import { useUserAuth } from "../../contexts/UserAuthContext";
import { FaSignOutAlt } from "react-icons/fa";

export function Header() {
  const navigate = useNavigate();
  const { logOut, user } = useUserAuth();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Flex
      w="full"
      position="sticky"
      top="0"
      zIndex="1"
      h={16}
      bgColor="brand.bgPrimary"
      boxShadow="sm"
    >
      <Container maxW="container.sm">
        <HStack h="full" justifyContent="space-between" alignItems="center">
          <Image src="logo.svg" alt="Roel.dad logo" w={10} fill="red" />
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              _focusVisible={{
                borderColor: "brand.black",
                boxShadow: "0 0 0 1px #323e48",
              }}
            >
              <Avatar w={10} h={10} src={user.photoURL} />
            </MenuButton>
            <MenuList>
              <Text px={3} py={1} fontWeight="bold">
                {user.displayName}
              </Text>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>
                <Icon as={FaSignOutAlt} mr={2} />
                Uitloggen
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Container>
    </Flex>
  );
}
