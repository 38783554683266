import { Container, Flex } from "@chakra-ui/react";
import { Header } from "./Header";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export function Layout({ children }: Props) {
  return (
    <Flex w="full" flexDir="column">
      <Header />
      <Container maxW="container.sm" p={0}>
        {children}
      </Container>
    </Flex>
  );
}
